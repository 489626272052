import isServer from './isServer';
import getEnvValue from './getEnvValue';
import getCookies from './getCookies';

const getStoreDetails = () => {
  if (isServer()) {
    return null;
  }
  const APP_VERSION =
    process.env.NEXT_PUBLIC_VERSION ?? getEnvValue('REACT_APP_VERSION') ?? '';
  const storeHostName =
    (APP_VERSION !== 'production' && getCookies().get('storeHostName')) ||
    window.location.hostname;

  switch (storeHostName) {
    case 'bk.fiore.buzz':
      return {
        id: '1',
        name: 'Brooklyn',
        zipCode: '11201',
      };
    case 'dc.fiore.buzz':
      return {
        id: '2',
        name: 'Washington',
        zipCode: '20005',
      };
    case 'chi.fiore.buzz':
      return {
        id: '4',
        name: 'Chicago',
        zipCode: '60610',
      };
    case 'weho.fiore.buzz':
      return {
        id: '6',
        name: 'West Hollywood',
        zipCode: '90069',
      };
    case 'sf.fiore.buzz':
      return {
        id: '7',
        name: 'San Francisco',
        zipCode: '94102',
      };
    case 'atx.fiore.buzz':
      return {
        id: '8',
        name: 'Austin',
        zipCode: '78758',
      };
    case 'sea.fiore.buzz':
      return {
        id: '9',
        name: 'Seattle',
        zipCode: '98122',
      };
    case 'soho.fiore.buzz':
      return {
        id: '10',
        name: 'Manhattan',
        zipCode: '10012',
      };
    case 'phil.fiore.buzz':
      return {
        id: '11',
        name: 'Philadelphia',
        zipCode: '19102',
      };
    case 'la.fiore.buzz':
      return {
        id: '12',
        name: 'Downtown Los Angeles',
        zipCode: '90021',
      };
    case 'den.fiore.buzz':
      return {
        id: '13',
        name: 'Denver',
        zipCode: '80206',
      };
    case 'vs.fiore.buzz':
      return {
        id: '5',
        name: 'Virtual Showroom',
        zipCode: '*',
      };
    case 'pdx.fiore.buzz':
      return {
        id: '14',
        name: 'Portland',
        zipCode: '97209',
      };
    case 'cs.fiore.buzz':
      return {
        id: '15',
        name: 'Customer Service',
        zipCode: '0',
      };
    case 'nis.fiore.buzz':
      return {
        id: '3',
        name: 'Nis',
        zipCode: '-1',
      };
    case 'lzbmia.fiore.buzz':
      return {
        id: '17',
        name: 'LZB Miami',
        zipCode: '-1',
      };
    default:
      return null;
  }
};

export default getStoreDetails;
